<template>
  <SuiModal v-model="dialog">
    <SuiModalContent style="height: 530px; display: flex; flex-direction:column">
      <a>
        <i
          @click="close()"
          style="text-decoration:none !important; cursor: pointer;font-size: 25px;
          position: sticky; left: 105%; top: 10px; margin-right: -5px; margin-top: -5px;"
          class="times icon general-blue-color">
        </i>
      </a>
      <SuiModalDescription style="width: 100%; padding-right:15px; padding-left:15px; display: flex; flex-direction: column;">
        <h1 style="margin-top: -15px">{{getCategory(edit_data.type)}}</h1>

        <form
          class="ui form fields"
          :class="{'blue medium double loading':isLoadingSave}"
          style="width: 100%; height: 80%; display: flex"
        >
          <div class="left" style="width: 50%; height: 100%">
            <div style="
              display: flex; justify-content: center;
              width: min-content; justify-content: space-between; margin-bottom: 8px;
              align-items: center; padding-left: -35px"
            >
              <label
                style="margin-right: 15px;color: #323232; font-size: 16px;">
                {{$t('pqrs.closed2')}}
              </label>
              <div
              class="ui toggle checkbox"
              style="transform: scale(1.3); margin-bottom: -10px;"
              >
                <input type="checkbox" :v-model="form.isOpen" :checked="form.isOpen" @change="changeActivation()">
                <label style="height: 0px; width:0px"></label>
              </div>
              <label
                style="margin-left: 10px;color: #323232; margin-left: 20px; font-size: 16px;">
                {{$t('pqrs.opened')}}
              </label>
            </div>

            <div class="title_field" style="width: 100%; justify-content: space-between; margin-bottom: 8px">
              <label style="font-size: 16px; color: #323232;">{{$t('pqrs.reportedBy')}}: {{ edit_data.name }}</label>
            </div>
            <div class="title_field" style="width: 100%; justify-content: space-between; margin-bottom: 8px">
              <label style="font-size: 16px; color: #323232;">{{$t('building.apartment')}}: {{ edit_data.apartment }}</label>
            </div>

            <!-- <div class="title_field" style="width: 100%; justify-content: space-between; margin-bottom: 8px">
              <label style="font-size: 16px; color: #323232;">{{$t('pqrs.report')}}: </label>
            </div> -->

              <!-- <div class="description_field" style="width: 100%">
                <div class="ui input" style="width: 100%">
                  <textarea
                    style="border: 1px solid rgba(34,36,38,.15); resize:none; width: 100%"
                   maxlength="200" :placeholder="$t('pqrs.headers.description')" rows="8" v-model="descriptionOfPQRS" disabled/>
                </div>
              </div> -->

              <div class="creationDate">
                <div class="date" style="width: 100%; display: flex; margin-top: 8px">
                  <label style="font-size: 16px; color: #323232;">{{$t('pqrs.headers.reportDate')}}:</label>
                  <label style="padding-left: 10px; font-size: 16px">{{form.createdAt}}</label>
                </div>
              </div>

              <div class="closeDate mt-3">
                <div class="date" style="width: 100%; display: flex;">
                  <label style="font-size: 16px; color: #323232">{{$t('pqrs.headers.closingDate')}}:</label>
                  <label style="padding-left: 10px; font-size: 16px">{{form.closeAt ?? "-"}}</label>
                </div>
              </div>

          </div>
          <div class="right" style="width: 50%; height: 100%; display: flex; align-items: center">
            <div
              class="right-content"
              style="height: 100%; width: 100%; margin-left: 50px; display: flex; flex-direction: column; gap: 16px;">
              <div>
                <div class="title_field" style="width: 100%; justify-content: space-between; margin-bottom: 8px">
                  <label style="font-size: 16px; color: #323232;">{{$t('pqrs.monthOfVigency')}}: </label>
                </div>
                <!-- <Select
                  style="border-radius: 4px;"
                  :search="true"
                  :options="months"
                  v-model="month"
                /> -->
                <select v-model='month'>
                  <option v-for="month in months" :key="month" :value="month">{{ month.description }}</option>
                </select>
              </div>
              <div>

                <div class="title_field" style="width: 100%; justify-content: space-between; margin-bottom: 8px">
                  <label style="font-size: 16px; color: #323232;">{{$t('pqrs.yearOfVigency')}}: </label>
                </div>
                <!-- <Select
                  style="border-radius: 4px;"
                  :search="true"
                  :options="years"
                  v-model="year"
                /> -->
                <select v-model='year'>
                  <option v-for="year in years" :key="year" :value="year">{{ year.description }}</option>
                </select>
              </div>
                <Button
                :appearance="classBtnObject"
                :text="$t('pqrs.approve')"
                color="primary"
                @clicked="generateSolvencyPDF"
                :class="{'disabled':isLoadingSave}"
              />
              <p v-if="edit_data.documentGenerated">

                <span v-if="edit_data.documentGeneratedAt"> {{
                $t('pqrs.solvency_certificate_generated') + " " +
                new Date(edit_data.documentGeneratedAt).toLocaleDateString('es-ES')
                }}</span>
              </p>
              <p v-else>
                {{$t('pqrs.solvency_certificate_not_generated')}}
              </p>
            </div>
          </div>
        </form>

        <div style="margin-top: 360px ;align-self:center; position: absolute" v-if="(v$.form.description.$error)">
          <span class="text-danger"  v-if="v$.form.description.$error">
            {{v$.form.description.$errors[0].$message }}
          </span>
        </div>

        <div class="footer" style="display:flex; justify-content: space-between;">
          <div style="width: 100%"></div>
          <div class="btns" :class="{'disabled':isLoadingSave}">
            <router-link :to="{
              path: `/messaging/direct/PQRS%20${this.edit_data.id}`,
              }"
              @click.prevent="navigateToMessage"
            >
              <div
                class="green-button-response"
              >
             {{$t('answer')}}
            </div>
            </router-link>
            <Button
            :appearance="classBtnObject"
            class="save"
            :text="$t('save')"
            color="primary"
            @clicked="saveChanges"
            :class="{'disabled':isLoadingSave}"
            />
          </div>
          <div
            class="isImportant_and_isOpen"
            style="width: 100%; display:flex; align-items: center; justify-content: flex-end;"
          >
            <div
              style="display: flex; flex-direction: column; justify-content: center;
              width: min-content; height: 100%; justify-content: space-between; padding-top: 10px;
              align-items: center; padding-left: -45px">
              <label style="margin-bottom: 8px;color: #323232;">{{$t('pqrs.headers.highlighted')}}</label>
              <i
                class="thumbtack icon"
                style="font-size: 20px; cursor: pointer"
                :class="classObjectIsImportant(form.important)"
                @click="changeImportance()"></i>
              <span style="color: #323232;">{{ form.important ? $t('yes') : $t('no')}}</span>
            </div>
          </div>
        </div>
      <CoverPicOfPQRSModal
      :show="isShowingCoverPic"
      :edit_data="coverPicModalData"
      @close="closeCoverPicModal"
      />
      </SuiModalDescription>
    </SuiModalContent>
  </SuiModal>
</template>

<script>
// SuiDropdown
import { SuiModal, SuiModalContent, SuiModalDescription } from 'vue-fomantic-ui'
import Button from '@/components/form/Button'
import CoverPicOfPQRSModal from '@/components/modals/CoverPicOfPQRSModal'
import { deletePQRS, updatePQRS } from '@/api/pqrs'
import { generateSolvencyCertificatePDF } from '@/api/solvency-certificate'
import { mapActions, mapGetters } from 'vuex'
import { es } from 'date-fns/locale'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, minLength } from '@vuelidate/validators/dist/raw.esm'

export default {
  props: {
    show: {
      type: Boolean,
      required: false,
      default: true
    },
    edit_data: {
      type: Object,
      required: false
    },
    isFullOfImportant: {
      type: Boolean,
      required: true
    }
  },
  components: {
    SuiModal,
    SuiModalContent,
    SuiModalDescription,
    Button,
    CoverPicOfPQRSModal
    // FileUploader
    // SuiDropdown,
    // Datepicker
    // SuiDropdown

  },
  emits: ['close'],
  setup: () => ({ v$: useVuelidate() }),
  data () {
    const currentYear = new Date().getFullYear()
    return {
      isShowingCoverPic: false,
      form: {
        type: '',
        imageUrl: [],
        description: '',
        createdAt: '',
        closeAt: '',
        important: false,
        isOpen: false
      },
      isLoadingSave: false,
      hours: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      nowDate: new Date(),
      countAttach: [0, 1, 2, 3, 4],
      month: { id: undefined, description: '' },
      months: [
        { id: 1, description: this.$t('pqrs.months.january') },
        { id: 2, description: this.$t('pqrs.months.february') },
        { id: 3, description: this.$t('pqrs.months.march') },
        { id: 4, description: this.$t('pqrs.months.april') },
        { id: 5, description: this.$t('pqrs.months.may') },
        { id: 6, description: this.$t('pqrs.months.june') },
        { id: 7, description: this.$t('pqrs.months.july') },
        { id: 8, description: this.$t('pqrs.months.august') },
        { id: 9, description: this.$t('pqrs.months.september') },
        { id: 10, description: this.$t('pqrs.months.october') },
        { id: 11, description: this.$t('pqrs.months.november') },
        { id: 12, description: this.$t('pqrs.months.december') }
      ],
      year: { id: undefined, description: '' },
      years: [
        { id: currentYear, description: currentYear.toString() },
        { id: currentYear + 1, description: (currentYear + 1).toString() }
      ]
    }
  },

  validations () {
    return {
      form: {
        description: {
          required: helpers.withMessage(this.$t('pqrs.addDescription'), required),
          minlength: helpers.withMessage(this.$t('pqrs.descriptionCharacters'), minLength(20))
        }
      }
    }
  },

  updated () {
    this.clearForm()
    this.form.type = this.edit_data.type
    this.form.description = this.edit_data.description
    this.form.imageUrl = this.edit_data.imageUrl
    this.form.important = this.edit_data.important
    this.form.isOpen = this.edit_data.isOpen
    this.getFiles()
    this.getImages()
    // setting Dates

    // Creation
    // const creationDate = new Date(this.edit_data.createdAt)
    // // console.log('Creation with raw date', this.edit_data.values.createdAt)
    // // console.log('Creation with new date', creationDate)
    // let creationDay = creationDate.getDate()
    // creationDay = creationDay < 10 ? `0${creationDay}` : creationDay
    // let creationMonth = creationDate.getMonth() + 1
    // creationMonth = creationMonth < 10 ? `0${creationMonth}` : creationMonth
    // this.form.createdAt = `${creationDay}/${creationMonth}/${creationDate.getFullYear()}`
    this.form.createdAt = this.getFormattedDate(this.edit_data.createdAt)

    if (this.edit_data.closeAt) {
      // const closeAtDate = new Date(this.edit_data.closeAt)
      // let cloaseAtDay = closeAtDate.getDate()
      // cloaseAtDay = cloaseAtDay < 10 ? `0${cloaseAtDay}` : cloaseAtDay
      // let cloaseAtMonth = closeAtDate.getMonth() + 1
      // cloaseAtMonth = cloaseAtMonth < 10 ? `0${cloaseAtMonth}` : cloaseAtMonth
      // this.form.closeAt = `${cloaseAtDay}/${cloaseAtMonth}/${closeAtDate.getFullYear()}`
      this.form.closeAt = this.getFormattedDate(this.edit_data.closeAt)
      this.form.important = this.edit_data.important
      this.form.isOpen = this.edit_data.isOpen
    }
  },
  computed: {

    ...mapGetters('bulletinBoard', [
      'allBulletins'
    ]),

    classBtnObject () {
      return {
        height: '29px',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        padding: '7px 2.4rem !important'
      }
    },

    es () {
      return es
    },

    expiresAt () {
      return `${this.form.expiresHour}:00:00 ${this.form.expiresHourPeriod}`
    },

    residential () {
      return this.$store.getters['residential/activeResidential']
    },
    dialog () {
      return this.show
    },

    images () {
      return this.getImages()
    },

    files () {
      return this.getFiles()
    },

    descriptionOfPQRS: {
      get () {
        return this.form.description
      },
      set (newVal) {
        this.form.description = newVal
      }
    }

  },
  methods: {
    ...mapActions('loading', ['setLoading']),
    ...mapActions('messaging', ['setResidentialMembersToMessage']),
    ...mapActions('pqrs', ['update_PQRS']),

    getFormattedDate (stringDate) {
      const fullDateObject = new Date(stringDate)
      const date = fullDateObject.getDate().toString().padStart(2, '0')
      const month = (fullDateObject.getMonth() + 1).toString().padStart(2, '0')
      const period = fullDateObject.getHours() >= 12 ? 'PM' : 'AM'
      const hour = fullDateObject.getHours() > 12 ? fullDateObject.getHours() - 12 : (fullDateObject.getHours() === 0 ? 12 : fullDateObject.getHours())
      return `${date}/${month}/${fullDateObject.getFullYear()} - ${hour.toString().padStart(2, '0')}:${fullDateObject.getMinutes().toString().padStart(2, '0')} ${period}`
    },

    changeImportance () {
      if (
        (this.isFullOfImportant && this.form.isOpen && !this.edit_data.important) ||
        (this.isFullOfImportant && !this.form.isOpen)) {
        this.$swal(this.$t('errors.pqrs.maxHighlighted'), '', 'error')
      } else {
        this.form.important = !this.form.important
        if (this.form.important) {
          this.form.isOpen = true
        }
      }
    },

    openCoverPicModal (pic) {
      this.coverPicModalData = pic
      this.isShowingCoverPic = true
    },

    closeCoverPicModal () {
      this.isShowingCoverPic = false
      this.coverPicModalData = null
    },

    classObjectIsImportant () {
      return {
        importantThumbtack: this.form.important,
        nonImportantThumbtack: !this.form.important
      }
    },

    changeActivation () {
      this.form.isOpen = !this.form.isOpen
      if (!this.form.isOpen) {
        this.form.important = false
      }
    },

    close () {
      this.$emit('close')
      this.clearForm()
      this.v$.$reset()
    },

    clearForm () {
      this.form = {
        type: '',
        imageUrl: [],
        description: '',
        createdAt: '',
        closeAt: '',
        important: false,
        isOpen: false
      }
      this.month = { id: undefined, description: '' }
      this.year = { id: undefined, description: '' }
    },

    getCategory (value) {
      switch (value) {
        case 1:
          return this.$t('pqrs.categories.harm')
        case 2:
          return this.$t('pqrs.categories.faults')
        case 3:
          return this.$t('pqrs.categories.complaint')
        case 4:
          return this.$t('pqrs.categories.claim')
        case 5:
          return this.$t('pqrs.categories.other')
        case 6:
          return this.$t('pqrs.categories.moving')
        case 7:
          return this.$t('pqrs.categories.solvency_certificate')
        default:
          return this.$t('pqrs.categories.other')
      }
    },

    async navigateToMessage () {
      const resident = {
        id: this.edit_data.userId,
        apartment: this.edit_data.apartment,
        firstName: this.edit_data.name,
        lastName: '',
        avatar: '',
        building: this.residential.name
      }
      // const title = `PQRS ${this.edit_data.id}`
      this.setResidentialMembersToMessage([resident])
      // this.$router.push({
      //   path: `/messaging/direct/${title}`,
      //   replace: true,
      //   params: { isMassive: true, isNew: true }
      // })
    },

    async saveChanges () {
      // this.isLoadingSave = true
      this.v$.$validate()
      const formValid = !(this.v$.$error)

      if (formValid) {
        this.setLoading(true)

        const data = {
          type: this.form.type.toString(),
          residentialId: this.residential.residentialId,
          description: this.form.description,
          isImportant: this.form.important.toString(),
          isOpen: this.form.isOpen.toString()
        }

        updatePQRS(this.edit_data.id, data).then((r) => {
          const pqrs = r.data
          this.update_PQRS({
            id: pqrs.id,
            important: pqrs.isImportant,
            isOpen: pqrs.isOpen,
            type: pqrs.typeId,
            imageUrl: pqrs.imageUrl,
            description: pqrs.description,
            createdAt: pqrs.createdAt,
            closeAt: pqrs.closeAt,
            apartment: pqrs.apartment,
            name: pqrs.name,
            documentGenerated: pqrs.documentGenerated,
            documentGeneratedAt: pqrs.documentGeneratedAt
          })
          // console.log(bulletin)
          // console.log(this.edit_data)
          // this.isLoadingSave = false
          this.close()
          this.$swal(this.$t('pqrs.updateSuccess'), '', 'success')
        }).catch(() => {
          // this.isLoadingSave = false
          this.$swal(this.$t('errors.pqrs.somethingWentWrong'), '', 'error')
        }).finally(() => {
          this.setLoading(false)
        })
      }

      // console.log('activationDate', completeDateActivation)
      // console.log('expiresAt', completeDateExpiration)
    },
    async generateSolvencyPDF () {
      // this.isLoadingSave = true
      // this.v$.$validate()
      console.log('generateSolvencyPDF method called', {
        pqrsId: this.edit_data.id,
        month: this.month?.id,
        year: this.year?.id
      })
      const formValid = this.month?.id && this.year?.id

      if (!formValid) {
        this.$swal('Debe seleccionar un mes y un año', '', 'error')
      }

      if (formValid) {
        this.setLoading(true)

        console.log('data', {
          pqrsId: this.edit_data.id,
          month: this.month?.id,
          year: this.year?.id
        })

        generateSolvencyCertificatePDF({
          pqrsId: this.edit_data.id,
          month: this.month?.id,
          year: this.year?.id
        }).then((r) => {
          console.log('PDF generado', r.data)
          // const pqrs = this.edit_data
          const pqrs = r.data
          this.update_PQRS({
            id: pqrs.id,
            important: pqrs.isImportant,
            isOpen: false,
            type: pqrs.typeId,
            imageUrl: pqrs.imageUrl,
            description: pqrs.description,
            createdAt: pqrs.createdAt,
            closeAt: pqrs.closeAt,
            apartment: pqrs.apartment,
            name: pqrs.name,
            documentGenerated: pqrs.documentGenerated,
            documentGeneratedAt: pqrs.documentGeneratedAt
          })

          this.close()
          this.$swal(this.$t('pqrs.updateSuccess'), '', 'success')
        }).catch(() => {
          this.$swal(this.$t('errors.pqrs.somethingWentWrong'), '', 'error')
        }).finally(() => {
          this.setLoading(false)
        })
      }
    },

    getFiles () {
      if (this.form.imageUrl && this.form.imageUrl.length > 0) {
        return this.form.imageUrl.filter((value) => value.includes('.pdf'))
      } else {
        return []
      }
    },

    getImages () {
      if (this.form.imageUrl && this.form.imageUrl.length > 0) {
        return this.form.imageUrl.filter((value) => (value.includes('.jpg') || value.includes('.png')))
      } else {
        return []
      }
    },

    async deleteBulletin () {
      this.$swal({
        title: this.$t('pqrs.delete'),
        showCancelButton: true,
        confirmButtonText: this.$t('yes'),
        confirmButtonColor: '#dc3545',
        cancelButtonText: this.$t('no'),
        cancelButtonColor: '#2186b0'
      }).then((result) => {
        if (result.isConfirmed) {
          // this.isLoadingSave = true
          this.setLoading(true)
          deletePQRS(this.edit_data.id).then(r => {
            this.delete_Bulletin(this.edit_data)
            /* console.log('data deleted')
            console.log(r)
            console.log('data in edit data')
            console.log(this.edit_data) */
            // this.isLoadingSave = false
            this.close()
            this.$swal(this.$t('pqrs.deleteSuccess'), '', 'success')
          }).catch(() => {
            // this.isLoadingSave = false
            this.$swal(this.$t('errors.pqrs.deleteError'), '', 'error')
          }).finally(() => {
            this.setLoading(false)
          })
        }
      })
    }

  }
}
</script>

<style scoped>
  h1 {
      color: #2c3e50 !important;
      font-family: inherit !important;
      }
  .btns {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  textarea:focus {
    border-color: #85b7d9 !important;
  }
  textarea::placeholder {
    color: rgba(34,36,38,.15)
  }
  textarea:focus::placeholder {
    color: rgb(151, 144, 144);
  }

  .file-list{
    display: flex;
    flex-direction: column;
  }

  .coverImage {
      max-height: 100px;
      max-width: 100%;
      margin: 5px;
      width: auto;
      object-fit: contain;
    }

  .title_field, .coverPhoto_field, .date_field, .description_field {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .description_existing_bulletin {
    margin-top: 30px
  }

    .importantThumbtack, .nonImportantThumbtack {
      color: #808080;
      font-size: 20px;
    }

    .importantThumbtack {
      color: rgb(235, 36, 36);
      /* transform: rotate(-45deg); */
      transform: rotate(0deg);
      transition: ease .3s
    }

    /* .importantThumbtack:hover {
      color: #808080;
      transform: rotate(-45deg)
    } */

    .nonImportantThumbtack {
      color: #808080;
      transform: rotate(-45deg);
      transition: ease .3s
    }

    /* .nonImportantThumbtack:hover {
      color: rgb(235, 36, 36);
      transform: rotate(0);
    } */
    .delete, .save {
      /* height: 40px !important;*/
      text-transform: none !important;
      letter-spacing: normal !important;
      border-radius: 5px !important;
      transition: background-color .5s ease !important;
      border: none !important;
      width: 10rem !important;
      font-family: inherit;
    }
  .delete {
    margin-right: 10px !important;
  }

  .save {
    margin-left: 10px !important;
  }

  .creationDate, .publicationDate, .expirationDate {
    width: 100%;
    display: flex;
    align-items: center
  }

  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  .green-button-response{
    background-color: var(--messaging-color) !important;
    color: white;
    padding: 7px 2.4rem; /*!important */
    text-transform: none !important;
    letter-spacing: normal;
    border-radius: 4px !important;
    box-shadow: none;
    transition: background-color .5s ease !important;
    height: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem !important;
    vertical-align: middle;
  }
</style>
