<template>
  <SuiModal v-model="dialog" >
    <SuiModalContent id="coverPicOfBulletinContent" style="display: flex; flex-direction:column">
      <a>
        <i
        @click="close()"
        style="text-decoration:none !important; cursor: pointer;font-size: 25px;
          position: sticky; left: 105%; top: 10px; margin-right: -5px; margin-top: -5px;"
        class="times icon general-blue-color">
        </i>
      </a>
        <SuiModalDescription style="width: 100%">
            <!-- <p>Pic of a bulletin</p> -->
            <!-- <p>{{edit_data}}</p> -->
            <div style="display: flex; justify-content: center; max-height: 400px">
              <img
                :src="edit_data"
                alt="cover picture of bulletin"
                style="max-height:100%; max-width:100%; display: block; object-fit: contain;">
            </div>
        </SuiModalDescription>
    </SuiModalContent>
  </SuiModal>
</template>

<script>
import { SuiModal, SuiModalContent, SuiModalDescription } from 'vue-fomantic-ui'
export default {
  components: {
    SuiModal,
    SuiModalContent,
    SuiModalDescription
  },
  props: {
    show: {
      type: Boolean,
      required: false,
      default: true
    },
    edit_data: {
      required: false
    }
  },
  computed: {
    dialog () {
      return this.show
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  },
  mounted () {
    document.getElementById('coverPicOfBulletinContent').parentElement.style.setProperty('width', 'auto', 'important')
    document.getElementById('coverPicOfBulletinContent').parentElement.style.setProperty('max-width', '1100px', 'important')
    // console.log('parent to change width', content)
  }
}
</script>
